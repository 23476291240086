import React, { useState, useEffect, useMemo } from "react"

// @ts-ignore
import * as styles from "../TableStyles.module.css"
import { MONTH_YEAR_ROLLOVER, GetYearFilterOptions, GetDefaultSelectedYear, FilterRecords } from "../../../../utils/reports"

function AbstractDataTable():JSX.Element {
  const [records,setAbstractRecords] = useState<Array<AbstractRegistrationRecord>>([]),
        [isLoading,setIsLoading] = useState<boolean>(false),
        [errorMessage,setErrorMessage] = useState<string>(""),
        [yearOptions,setYearOptions] = useState<Array<number>>([]),
        [filteredYear,setFilteredYear] = useState<number>()

  function loadRecords () {
    setIsLoading(true)
    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/reports?t=abstracts`, {
      method: "GET"
    })
    .then((response)=>{
      response.text()
      .then((data:string)=>{
        let response = JSON.parse(data)
        let newRecords = response.data.map((ref:any)=>{
          return {
            ...ref.data,
            last_modified: new Date(ref.ts/1000)
          }
        }).filter((a:any) => { return a ? true : false})
        setAbstractRecords(newRecords)
        setFilteredYear(GetDefaultSelectedYear())
        setYearOptions(GetYearFilterOptions())
        setIsLoading(false)
      })
      .catch((err)=>{
        setIsLoading(false)
        setErrorMessage("Failed to load records")
      })
    })
    .catch((err)=>{
      setIsLoading(false)
      setErrorMessage("Failed to load records")
    })
  }

  useEffect(()=>{
    loadRecords()
  }, [])

  function copyData() {
    let data = records.map((record) => {
      return [
        record.last_modified?.toLocaleString(),
        record.firstName,
        record.lastName,
        record.affiliation,
        record.address1,
        record.address2,
        record.city,
        record.state,
        record.zip,
        record.country,
        record.telephone,
        record.email,
        record.presenter,
        record.delivery_option,
        record.abstract_title,
        record.authors,
        record.abstract
      ].join('\t')
    }).join('\n')
    navigator.clipboard.writeText(data)
  }

  function onUpdateFilter(e:React.ChangeEvent<HTMLSelectElement> ) {
    try {
      let newYear :number|undefined
      newYear = parseInt(e.target.value)
      if(newYear && newYear > 2020) {
        setFilteredYear(newYear)
        return
      }
    } catch(err) {
    }
    setFilteredYear(undefined)
  }

  const visibleResults:Array<FormRecord> = useMemo(()=>{
    return FilterRecords(records, filteredYear)
  },[yearOptions,filteredYear,records])

  return (
    <div>
      <div className={styles.infoBar}>
        <div>
          <button onClick={copyData}>Copy</button>
          <p style={{paddingLeft: 10}}>{`${visibleResults.length} Records`}</p>
        </div>
        {isLoading ? (<p>Loading...</p>) : null}
        <div>
          <span className={styles.field}>
            <label title={`Years rollover on the ${MONTH_YEAR_ROLLOVER+1} month `}>Year</label>
            <select value={filteredYear} onChange={onUpdateFilter}>
              <option value="">All</option>
              {yearOptions.map((yr)=>(
                <option key={yr} value={yr}>{`${MONTH_YEAR_ROLLOVER+1}/${yr-1} through ${MONTH_YEAR_ROLLOVER}/${yr}`}</option>
              ))}
            </select>
          </span>
        </div>
      </div>
      {errorMessage ? (<p>{errorMessage}</p> ) : null}
      <div className={styles.tableWrapper}>
        <table className={styles.table} id="abstractTable">
          <thead>
            <tr>
              <th>Date</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Affiliation</th>
              <th>Address 1</th>
              <th>Address 2</th>
              <th>City</th>
              <th>State</th>
              <th>Zip</th>
              <th>Country</th>
              <th>Telephone</th>
              <th>Email</th>
              <th>Presenter</th>
              <th>Delivery Option</th>
              <th>Abstract Title</th>
              <th>Authors</th>
              <th className={styles.limited}>Abstract</th>
            </tr>
          </thead>
          <tbody>
            {visibleResults.map((r, idx)=>{
              let record = r as AbstractRegistrationRecord
              return (
                <tr key={record.client_reference_id || idx}>
                  <td>{record.last_modified?.toLocaleString()}</td>
                  <td>{record.firstName}</td>
                  <td>{record.lastName}</td>
                  <td>{record.affiliation}</td>
                  <td>{record.address1}</td>
                  <td>{record.address2}</td>
                  <td>{record.city}</td>
                  <td>{record.state}</td>
                  <td>{record.zip}</td>
                  <td>{record.country}</td>
                  <td>{record.telephone}</td>
                  <td>{record.email}</td>
                  <td>{record.presenter}</td>
                  <td>{record.delivery_option}</td>
                  <td>{record.abstract_title}</td>
                  <td>{record.authors}</td>
                  <td className={styles.limited}>{record.abstract}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AbstractDataTable